import * as React from 'react';
import { Link } from 'react-router-dom';

import {  Box, Card, CardContent, CardMedia, CardActionArea, Typography, Divider} from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';

import DeepLearningImage from '../../assets/DeepLearning_Image.jpeg'
import DeepLearningImage1 from '../../assets/DeepLearning_1_Image.jpg'
import DeepLearningImage2 from '../../assets/DeepLearning_2_Image.jpg'
import DeepLearningImage3 from '../../assets/DeepLearning_3_Image.jpg'
import DeepLearningImage4 from '../../assets/DeepLearning_4_Image.jpg'

import ArticleImageCardSumit from '../../components/ArticleImageCardSumit';
import FourCardsLinksSumit from '../../components/FourCardsLinksSumit';

function DeepLearning() {
  
  const message = "At aiQuest, we're passionate about democratizing AI knowledge. Founders Sumit and Vineet, both seasoned professionals and AI enthusiasts, believe that AI shouldn't be an exclusive club.\
  That's why we've built a unique learning platform designed for you. We break down complex concepts into easy-to-understand steps, offering a customizable learning path that fits your goals and pace.\
  Join our journey to make AI accessible to everyone!"

  
return(
    <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined" >
            <CardContent>         
                <ArticleImageCardSumit
                    article_image = {DeepLearningImage} 
                    artile_heading = "Deep Learning"
                    article_tagline = "Create New things like text, images, and even videos!"
                />

                <br/>
                <Divider />
                <br/>
                <Typography variant="body2" >
                      {message}      
                </Typography>                
            </CardContent>
        </Card>     

        <br />
        <br />

        <FourCardsLinksSumit 
          link1="/coming-soon" 
          image1 = {DeepLearningImage1}
          heading1="Foundational Concepts" 
          description1="Learn about LLMs (Large Language Models)"
          

          link2="/coming-soon" 
          image2 = {DeepLearningImage2}
          heading2="Applications of DL" 
          description2="Learn how GenAI can be used for applications like Chat Bots, Summerization, Reccomendations, etc"
          

          link3="/coming-soon" 
          image3 = {DeepLearningImage3}
          heading3="Ethics and DL" 
          description3="Teach machines to think for themselves."


          link4="/coming-soon" 
          image4 = {DeepLearningImage4}
          heading4="Latest News" 
          description4="GenAI is fast Evolving, learn the latest"        
        />  

    </Box>
  
    );
  }
  
  export default DeepLearning;
  