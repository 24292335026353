import * as React from 'react';
import { Link } from 'react-router-dom';

import { Stack, Box, Card, CardContent, CardMedia, CardActionArea, Typography} from '@mui/material';




// import Sumit_Image from '../assets/SumitMathur.jpeg'
// import Vineet_Image from '../assets/VineetSaini.jpg'
import AiQuestLogo_Image from '../assets/AiQuestLogo.jpg'

const AboutUs = () => {
    const message = "At aiQuest, we're passionate about democratizing AI knowledge. Founders are seasoned professionals and AI enthusiasts, believe that AI shouldn't be an exclusive club.\
        That's why we've built a unique learning platform designed for you. We break down complex concepts into easy-to-understand steps, offering a customizable learning path that fits your goals and pace.\
        Join our journey to make AI accessible to everyone!. We would love to hear from you contact@aiquest.in"

        
    return(
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined" >
                <CardContent>                          
                    <Card  sx={{ height:130, width:220, float: 'left', mr: 1.2, mb: 1.2  }}>                
                        <img
                            src={AiQuestLogo_Image}
                            style={{ height:130, width:220 }}
                        />                    
                    </Card>                            
                    <Typography variant="body2" >
                        {message}      
                    </Typography>                
                </CardContent>
            </Card>  
        
            <br/>
        
            {/* <Stack 
                direction={{ sm: 'column', sm: 'row' }} 
                spacing={{ xs: 2, sm: 2, md: 5 }}
                justifyContent="center" 
                alignItems="flex-start"             
            >
            
                <Card sx={{ width: 300, height:500 }}>
                        <Link to = '/about-us-sumit' style={{ textDecoration: 'none' }}>
                        <CardActionArea >                
                        
                            <CardMedia
                            sx={{ height: 300 }}
                            image={Sumit_Image}                                    
                            />
                            
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Sumit Mathur
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Sumit is an experience profession with other 25 years of experience in Banking Industry.
                                    He has worked in WallSteet for 10 years and has a Masters in AI from GeorgiaTech
                                </Typography>
                            </CardContent>
                        
                        </CardActionArea>
                        </Link>
                    </Card>     
                    
                
                    <Card sx={{ width: 300, height:500 }}>
                        <Link to = '/about-us-vineet' style={{ textDecoration: 'none' }}>
                        <CardActionArea >                
                        
                            <CardMedia
                            sx={{ height: 300 }}
                            image={Vineet_Image}                                    
                            />
                            
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Vineet Saini
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Vineet has over 15 years of rich experience in Baking Technology. 
                                    He has in-depth knowledge in AI. He has a Msters in AI from GeorgiaTech.
                                </Typography>
                            </CardContent>
                        
                        </CardActionArea>
                        </Link>
                    </Card>    
                </Stack> */}
        
        </Box>


    )

}

export default AboutUs