function Classification() {
    return (
      <div>
      <h1>Classification</h1>
        <p>
            Logistic Regression
        </p>      
      </div>
    );
  }
  
  export default Classification;
  