import * as React from 'react';

import {Box, Card, Typography} from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';


function ArticleImageCardSumit(props){
    return (
        <Card >
            <Box sx={{ position: 'relative' }}>
                <Card >                
                <AspectRatio minHeight={300} maxHeight={100} >
                    <img
                        src={props.article_image}                        
                    />                    
                    </AspectRatio>
                </Card> 
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        bgcolor: 'rgba(0, 0, 0, 0.54)',
                        color: 'white',
                        padding: '10px',
                    }}
                    >
                    <Typography variant="h5">{props.artile_heading}</Typography>
                    <Typography variant="body2">{props.article_tagline}</Typography>
                </Box>
            </Box>        
        </Card>

    )


}

export default ArticleImageCardSumit;