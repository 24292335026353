function TimeSeries() {
    return (
      <div>
      <h1>Time Series</h1>
        <p>
            Time Series
        </p>      
      </div>
    );
  }
  
  export default TimeSeries;
  