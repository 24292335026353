import * as React from 'react';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AiQuestLogo_Image from '../assets/AiQuestLogo.jpg'
import Header_Image from '../assets/Header_Image.png'



function Header() {  
  return (
    <Box sx={{ minWidth: 275}}> 
        <Card sx={{ minWidth: 275, height:50, m:0}}>   
        <Link to ="/" style={{ textDecoration: 'none' }}>
          <CardContent sx={{m:-2}}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <div> 
              <Box >
                  <img src={Header_Image} height={14} width={300}/>
              </Box>
              <Typography variant="h5" component="div">
              &nbsp;Welcome to aiQuest
              </Typography>
            </div>
            <div> 
              <img src={AiQuestLogo_Image} height={50} width={85} />
            </div>
            </Stack>

          </CardContent>
          </Link>
        </Card>
      </Box>
  );
}

export default Header;
