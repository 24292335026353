function BasianModels() {
    return (
      <div>
      <h1>Basian Models</h1>
        <p>
            Basian Models
        </p>      
      </div>
    );
  }
  
  export default BasianModels;
  