import { Link, NavLink } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';

import {
  FaHome,
  FaMedapps,
  FaSquareRootAlt ,
  FaUserFriends,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaHashtag,
  FaRegStar 
} from 'react-icons/fa';

import {
  BiBrain,
  BiDesktop 
} from 'react-icons/bi';

import {
  GoGraph  
} from 'react-icons/go';


import sidebarBg from '../assets/bg1.jpg';

const Sidebar = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange
}) => {
  return (
<ProSidebar
      // image={sidebarBg}
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="md"
    >
      {/* ----------------------    Header ------------------- */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem
              icon={<FaAngleDoubleRight />}
              onClick={handleCollapsedChange}
            ></MenuItem>
          ) : (
            <MenuItem
              suffix={<FaAngleDoubleLeft />}
              onClick={handleCollapsedChange}
            >
              <div
                style={{
                  padding: '9px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: 15,
                  letterSpacing: '1px'
                }}
              >
                AI RoadMap
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* ---------------------- Header End ------------------- */}

      <SidebarContent>
        
        <Menu iconShape="square">
          
          <MenuItem
            icon={<FaHome />}
            // suffix={<span className="badge red">NEW</span>}
          >
            Home <NavLink to="/" />
            
          </MenuItem>


          <SubMenu title="GenAI" icon={<FaMedapps />}>
            <MenuItem> What is GenAI <Link to="/genai"/> </MenuItem>
            <MenuItem> Foundational Concept <Link to="/genai-llm"/> </MenuItem>
            <MenuItem> Applications of Gen AI <Link to="/coming-soon"/> </MenuItem>
            <MenuItem> Ethics and GenAI <Link to="/coming-soon"/> </MenuItem>
            <MenuItem> Latest News <Link to="/coming-soon"/> </MenuItem>
          </SubMenu>

          <SubMenu title="DeepLearning" icon={<BiBrain />} >
            <MenuItem> What is DeepLearning <Link to="/deeplearning"/> </MenuItem>
            <MenuItem>NLP <Link to="/deeplearning-nlp"/> </MenuItem>
            <SubMenu title="Neural Networks">
              <MenuItem>What is a Neural Network<Link to="/deeplearning-neuralnetworks"/> </MenuItem>
              <MenuItem>RNN <Link to="/deeplearning-neuralnetworks-rnn"/></MenuItem>
            </SubMenu>
          </SubMenu>


          <SubMenu title="Machine Learning" icon={<BiDesktop />}  >
            <MenuItem> What is Machine Learning <Link to="/ml"/> </MenuItem>
            <MenuItem> Regression <Link to="/ml-regression"/> </MenuItem>
            <MenuItem> Classification <Link to="/ml-classification"/> </MenuItem>
            <MenuItem> Time Series <Link to="/ml-timeseries"/> </MenuItem>
            <MenuItem> Basian Models <Link to="/ml-basian"/> </MenuItem>
          </SubMenu>
        </Menu>

        

        
        <Menu iconShape="square">
          {/* To add space in munue */}
          <MenuItem>  </MenuItem>   
          <MenuItem>  </MenuItem>          
          <MenuItem  icon={<FaRegStar />}> Core Concepts </MenuItem>  
          <SubMenu title="Statistics and Probability" icon={<GoGraph />}>
            <MenuItem> What is GenAI <Link to="/genai"/> </MenuItem>
            <MenuItem> LLMs <Link to="/genai-llm"/> </MenuItem>
            <MenuItem> SLM <Link to="/genai-slm"/> </MenuItem>
          </SubMenu>
          <SubMenu title="Linear Algebra" icon={<FaHashtag   />}>
            <MenuItem> What is GenAI <Link to="/genai"/> </MenuItem>
            <MenuItem> LLMs <Link to="/genai-llm"/> </MenuItem>
            <MenuItem> SLM <Link to="/genai-slm"/> </MenuItem>
          </SubMenu>
          <SubMenu title="Calculus and others" icon={<FaSquareRootAlt  />}>
            <MenuItem> What is GenAI <Link to="/genai"/> </MenuItem>
            <MenuItem> LLMs <Link to="/genai-llm"/> </MenuItem>
            <MenuItem> SLM <Link to="/genai-slm"/> </MenuItem>
          </SubMenu>
        </Menu>

      </SidebarContent>

      {/*  --------------- Footer --------------*/}
      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper" style={{ padding: '16px' }}>   
            <Link to="/about-us" style={{ textDecoration: 'none', color: 'inherit'}}>             
              <FaUserFriends />
              <span>&nbsp;About Us</span>
            </Link>      
        </div>
      </SidebarFooter>
      {/*  --------------- Footer End--------------*/}
      
    </ProSidebar>
  );
};

export default Sidebar;
