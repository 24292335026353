import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function FourCardsLinksSumit(props) {
    return (

        <Box>
        <Stack 
            direction={{ sm: 'column', sm: 'row' }} 
            spacing={{ xs: 2, sm: 2, md: 3 }}
        >        
        
            <Card sx={{width:{sm: 1/4}, height:280 }}>
                <Link to ={props.link1} style={{ textDecoration: 'none' }}>
                <CardActionArea >                
                
                    <CardMedia
                    sx={{ height: 140 }}
                    image={props.image1}                                    
                    />
                    
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                        {props.heading1}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {props.description1}
                        </Typography>
                    </CardContent>
                
                </CardActionArea>
                </Link>
            </Card>     
            
        
            <Card sx={{ width:{sm: 1/4},height:280 }}>
                <Link to ={props.link2} style={{ textDecoration: 'none' }}>
                <CardActionArea >                
                
                    <CardMedia
                    sx={{ height: 140 }}
                    image={props.image2}                                    
                    />
                    
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                        {props.heading2}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {props.description2}
                        </Typography>
                    </CardContent>
                
                </CardActionArea>
                </Link>
            </Card>    

        
            <Card sx={{width:{sm: 1/4},height:280 }}>
                <Link to ={props.link3} style={{ textDecoration: 'none' }}>
                <CardActionArea >                
                
                    <CardMedia
                    sx={{ height: 140 }}
                    image={props.image3}                                    
                    />
                    
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                        {props.heading3}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {props.description3}
                        </Typography>
                    </CardContent>
                
                </CardActionArea>
                </Link>
            </Card>    
        

            <Card sx={{ width:{sm: 1/4},height:280 }}>
                <Link to ={props.link4} style={{ textDecoration: 'none' }}>
                <CardActionArea >                
                
                    <CardMedia
                    sx={{ height: 140 }}
                    image={props.image4}                                    
                    />
                    
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                        {props.heading4}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {props.description4}
                        </Typography>
                    </CardContent>
                
                </CardActionArea>
                </Link>
            </Card>    
            
        </Stack>

        </Box>
        
        
)
}

export default FourCardsLinksSumit;