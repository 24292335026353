function NeuralNetworks() {
    return (
      <div>
      <h1>What is a Neural Network</h1>
        <p>
            Neural Network
        </p>      
      </div>
    );
  }
  
  export default NeuralNetworks;