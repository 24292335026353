import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AspectRatio from '@mui/joy/AspectRatio';
import Hidden from '@material-ui/core/Hidden';

import RAG_Image01_lg from './Foundational_Concepts_RAG_Images/RAG_Image01-lg.png'
import RAG_Image01_md from './Foundational_Concepts_RAG_Images/RAG_Image01-md.png'
import RAG_Image02_lg from './Foundational_Concepts_RAG_Images/RAG_Image02-lg.png'
import RAG_Image02_md from './Foundational_Concepts_RAG_Images/RAG_Image02-md.png'
import RAG_Image03_lg from './Foundational_Concepts_RAG_Images/RAG_Image03-lg.png'
import RAG_Image03_md from './Foundational_Concepts_RAG_Images/RAG_Image03-md.png'
import RAG_Image04 from './Foundational_Concepts_RAG_Images/RAG_Image04.png'
import RAG_Image05 from './Foundational_Concepts_RAG_Images/RAG_Image05.png'
import RAG_Image06 from './Foundational_Concepts_RAG_Images/RAG_Image06.png'
import RAG_Image07 from './Foundational_Concepts_RAG_Images/RAG_Image07.png'
import RAG_Image08 from './Foundational_Concepts_RAG_Images/RAG_Image08.png'

import RAG_zip from './Foundational_Concepts_Files/RAG_Code.zip'



const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = ( 
  <React.Fragment >
    <CardContent>
      <Typography variant="h5" component="div">
        1. Whats is RAG
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        The Story
      </Typography>
      <Typography variant="body2">
        <p>Once upon a time in the heart of the bustling city, there stood a prestigious corporate law firm named “LexCorp.” LexCorp was renowned for its expertise in navigating complex legal mazes, from mergers and acquisitions to tax compliance. The firm’s founder, RAGni, was a seasoned attorney with a sharp mind and a passion for justice. </p>
        <p>As LexCorp’s reputation grew, so did the influx of clients seeking legal advice. Entrepreneurs, CEOs, and business owners flocked to RAGni’s office, each with their unique set of questions. Some needed guidance on intellectual property rights, while others grappled with employment contracts or shareholder agreements.</p>
        <p>RAGni, however, was stretched thin. Court hearings, negotiations, and client meetings consumed her days. She needed a solution—a legal prodigy who could handle the day-to-day inquiries while she fought the high-stakes battles.</p>
        
        <Hidden smDown>
        <Card >                
          
              <img
                  src={RAG_Image01_lg}                        
              />                    
          
        </Card> 
        </Hidden>

        <Hidden mdUp>
        <Card >                
          
              <img
                  src={RAG_Image01_md}                        
              />                    
          
        </Card> 
        </Hidden>

        <p>Enter Lakhvir Lal Man (LLM), a fresh-faced law graduate. LLM had aced his bar exams and had a knack for understanding legal nuances. RAGni saw potential in him and hired him as LexCorp’s resident legal specialist.</p>
        <p>LLM’s journey began. His general knowledge on COpporate Law was impressive; he could explain the intricacies of tax codes and contract clauses with ease. Clients loved him—they called him the “Legal Oracle.”</p>
        <p>Lakhvir however was not and Employee of LexCopp, but rather a consultant from a major consulting firm.</p>
        
        <Hidden smDown>
        <Card >                
          
              <img
                  src={RAG_Image02_lg}                        
              />                    
          
        </Card> 
        </Hidden>

        <Hidden mdUp>
        <Card >                
          
              <img
                  src={RAG_Image02_md}                        
              />                    
          
        </Card> 
        </Hidden>

        <p>But there was a catch. LLM lacked LexCorp’s context. He didn’t know the specifics of LexCorp’s client contracts, the company’s preferred negotiation strategies, or the intricacies of their legal precedents. When clients asked firm-specific questions, LLM stumbled. His answers were sometimes incorrect, leaving clients perplexed. He "hallucinated" in these scenarios</p>
        <p>RAGni recognized the gap. She was not comfortable sharing her company’s interal information with Lakhvir. She needed a bridge between LexCorp’s internal knowledge and Lakhvir’s expertise. </p>
        <p>That’s when she had a brilliant idea. Enter Rita Pal, affectionately known as “Retriever.” Rita was a seasoned researcher with a knack for digging up hidden gems. She had access to LexCorp’s internal documentation, including case studies, legal memos, and client-specific details. RAGni hired her as the missing link. </p>
        <p>Now, whenever a client posed a question, Rita sprang into action. She dove into LexCorp’s archives, sifting through legal precedents, contracts, and regulatory updates. Her mission: retrieve relevant snippets of information. Rita was like a legal detective, piecing together the puzzle.</p>
        <p>On top of this RAGni crafted instructions for Lakhvir, guiding him on how to weave the retrieved snippets into coherent answers</p>
        <p>Rita sent the curated snippets and RAGni’s instructions to Lakhvir. Armed with this powerful combination, Lakhvir transformed into the “Contextual Legal Maestro.” His responses were precise, backed by LexCorp’s data, and infused with RAGni’s strategic thinking.</p>
        
        <Hidden smDown>
        <Card >                          
          <img
              src={RAG_Image03_lg}                        
          />                             
        </Card> 
        </Hidden>

        <Hidden mdUp>
        <Card >                          
          <img
              src={RAG_Image03_md}                        
          />                            
        </Card> 
        </Hidden>

        <p>Clients marveled at Lakhvir’s accuracy. They praised LexCorp’s responsiveness and depth of knowledge. Rita, RAGni, and Lakhvir formed an unstoppable trio</p>
        <p>And that, my friend, is the story: where a seasoned attorney, a resourceful retriever, and a promising specialist joined forces to create an intelligent, context-aware legal chatbot.</p>
        <br />        
      </Typography>
      
      
      <br></br>
      <br></br>
      <Typography variant="h5" component="div">
        2. Demystified Technically
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Technical overview
      </Typography>
      <Typography variant="h6" gutterBottom> 2.1 Key Concepts </Typography>
        <ul>
          <li>But whats is an LLM ?</li>
          <li>What is vectorization and Semantic Search ?</li>
          <li>... and what about Prompt Engineering ?</li>
        </ul>

        <p><b>LLM:</b> A Large Language Model (LLM) is a type of artificial intelligence that can understand and generate human language. Think of it as a super-smart text predictor. It learns from a huge amount of text data and can then help with tasks like writing, answering questions, or even having conversations. It’s like having a very knowledgeable assistant that can talk and write like a human!</p>
        <p><b>Vectorization:</b> This process converts text into numerical vectors. Think of it as translating words into a language that computers can understand and compare. Each word or piece of text is represented as a vector, which is a list of numbers. These numbers capture the meaning and context of the words. Imagine the word “cat” is converted into a vector like [0.2, 0.8, 0.1]. This vector is a numerical representation of the word “cat”.</p>
        <p><b>Prompt Engineering:</b> Instructions you give to an AI to get the best possible answers.</p>
        <br></br>
      

      <Typography variant="body2">
        <Typography variant="h6" gutterBottom> 2.2 Setting up Rita Pal’s Library a.k.a  Documents Store </Typography>
        <img src={RAG_Image04} width="400px" height="300px" />  
        
        <p><b>Data Sources:</b>The sources from which information is retrieved can vary widely, including structured databases, unstructured text collections, scientific journals, or the entire web. The choice of data source depends on the application and the type of information needed.</p>
        <p><b>Data Store Management: </b>Vector Databases are specialized storage systems designed to efficiently store, manage, and retrieve high-dimensional vector data. These vectors are often representations of text, images, or other complex data types that have been transformed into numerical formats using machine learning models, particularly those based on neural networks. In the context of RAG, vectors typically represent the semantic embeddings of text data, which can include anything from short queries to extensive documents.</p>
        <p>Wait, Semantic Embeddings ? </p>
        <p>Examples of Vector DB’s a.k.a Library organizer- </p>
        <p>Elasticsearch, PineCone, FAISS, Weviate & others.</p>

        <br></br>
        <Typography variant="h6" gutterBottom> 2.3 Setting up Rita Pal a.k.a Efficient Retriever </Typography>
        <img src={RAG_Image05} width="300px" height="300px" />  
        <p><b>Retrieval Techniques: </b></p>
        <p>Advanced retrieval techniques, such as vector search or semantic search, are employed to find information that is contextually related to the query. These techniques use machine learning models to understand the query's semantics and retrieve documents that have similar meanings, rather than relying solely on keyword matching</p>
        <p>Examples - Open AI Embeddings, BERT, Word to Vec, Sentence Transformers.</p>
        <br></br>
        
        <Typography variant="h6" gutterBottom> 2.4 Enabling Lakhvir Lal Man a.k.a Deploying LLM </Typography>
        <img src={RAG_Image06} width="300px" height="300px" />  
        <p><b>Language Model Capabilities: </b>The generative model, often a variant of GPT or a similar transformer-based model, uses its understanding of language and context to craft responses. These models are trained on vast amounts of text, enabling them to generate human-like text based on the inputs they receive.</p>
        <p><b>Customization and Tuning:</b>The generation phase can be customized and fine-tuned for specific applications. This includes adjusting the model to generate more detailed explanations, creative content, or concise summaries, depending on the requirements of the task.</p>
        <p>LLM Examples: <a href="https://huggingface.co/spaces/vectara/Hallucination-evaluation-leaderboard">Link</a></p>

      </Typography>
      <Card >                
          
              <img
                  src={RAG_Image07}                        
              />                    
          
      </Card> 
      <br></br> 
      <br></br>
      <Typography variant="h6" gutterBottom> 2.5 The Python Code </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        Jupyter Notebook
      </Typography>


      <br></br>
      <br></br>
      <br></br>
      <Typography variant="body2">        
      <Typography variant="h5" gutterBottom> 3. Industrial Implementation</Typography>
        <Typography variant="h6" gutterBottom> 3.1 LLMOps - Building Production Pipeline</Typography>
        <img src={RAG_Image08} width="500px" height="300px" />  
        <p><b>Key Considerations:</b></p>
        <ul>
          <li><b>Model Infrastructure:</b> Deploying on your own Infra Vs Managed Service like GPT.</li>
          <li><b>Data Management:</b>Choosing a right data store, Data Ingestion & organisation.</li>
          <li><b>Model Development:</b>Model fine-tuning, evaluation, documentation & versioning.</li>
          <li><b>Model Integration & Deployment:</b>GPU assessment, LLM Chain, Load Balancing, Endpoint Creation.</li>
          <li><b>Security and Privacy:</b>Guardrails, access controls, PII , compliance, confidentiality.</li>
          <li><b>Model Monitoring:</b>Model Drift, Hallucination rate, Cost, Latency, Feedback Loop, resource Utilization</li>

        </ul>

        <br></br>
        <Typography variant="h6" gutterBottom> 3.2 RAG Challenges and how to overcome those with Guardrails</Typography>        
        <p><b>Challenges:</b></p>
        <ul>
          <li>Hallucination</li>
          <li>Prompt Injection</li>
          <li>HAP</li>
        </ul>

        <p><b>Guardrails:</b></p>
        <ul>
          <li>Model Tuning & Prompt Engineering</li>
          <li>Probabilistic models to detect prompt injection</li>
          <li>HAP Moderation Layer: Rule Based Methods and ML models</li>
        </ul>
        <br></br>

        <Typography variant="h5" component="div">
          4. Examples of Industrial implementation of RAG
        </Typography>
        <ul>
          <li><b>Customer Service:</b> RAG-enhanced chatbots are used to provide more accurate and contextually relevant responses by retrieving up-to-date information from databases and knowledge bases. This improves customer satisfaction and reduces response times</li>
          <li><b>Education and Research:</b> RAG is used to enhance educational tools and research platforms by providing students and researchers with access to the latest studies, papers, and educational resources. This supports more effective learning and research outcomes</li>
          <li><b>Legal Research and Compliance:</b> Legal professionals use RAG to quickly access relevant case laws, regulations, and legal documents. This streamlines the research process and ensures that legal advice and compliance checks are based on the most current information</li>
          <li><b>Healthcare:</b> In the medical field, RAG aids in diagnosis and treatment planning by retrieving the latest medical research and patient data. This helps healthcare professionals make informed decisions based on the most recent and relevant information2.</li>
          <li><b>E-commerce:</b> RAG personalizes the shopping experience by retrieving product information, reviews, and recommendations tailored to individual customer preferences. This enhances the overall shopping experience and increases customer engagement1</li>
          <li><b>Financial Analysis:</b> In the finance sector, RAG is used to analyze market trends, financial reports, and other relevant data. This helps analysts make better investment decisions and provides more accurate financial advice</li>
        </ul>
        <br></br>

        <Typography variant="h5" component="div">
          5. Summary and a Quick Recap
        </Typography>
        <ul>
          <li>Whats is RAG </li>
          <li>Technical Overview of RAG Archiecture </li>
          <li>Industrial Implementation, Practicle issues and how to overcome them</li>
          <li>Examples of Industrial implementation of RAG</li>          
        </ul>



      </Typography>


      

      

    </CardContent>
    <CardActions>
      <Button onClick={onDownload} size="small">Click here to get access to the Code</Button>
    </CardActions>
  </React.Fragment>
);

function LLM() {
    return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
    );
  }

  function onDownload() {
    const link = document.createElement("a");
    link.download = `RAG_Code.zip`;
    link.href = RAG_zip;
    link.click();
  };
  
  export default LLM;
  