import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AspectRatio from '@mui/joy/AspectRatio';
import Hidden from '@material-ui/core/Hidden';


import ML_image1 from './ML_Images/AI_ML_DL_GenAI.png'
import ML_image2 from './ML_Images/ML_Sub_Types.png'
import ML_image3 from './ML_Images/Mr_Singh.png'
import ML_image4 from './ML_Images/CreditCard_Factors.png'
import ML_image5 from './ML_Images/Sumit_Contact_QR.jpg'
import ML_image6 from './ML_Images/Vineet_Contact_QR.jpg'

import Card_zip from './ML_Files/Credit_Card_Default.zip'


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


const card = ( 
  <React.Fragment >
    <CardContent>

      <Card >
        <Typography variant="h4" component="div">
          Topic: Predictive Supervised ML Pipeline with Banking Industry use cases
        </Typography>
      </Card>

      <br /><br /><br />

      <Typography variant="h5" component="div">
        1. Key Concepts Refresher
      </Typography>
      
      <Hidden smDown>
        <Card >                          
          <img
              src={ML_image1}                        
          />                              
        </Card> 
        </Hidden>

        <Hidden mdUp>
        <Card >                          
          <img
              src={ML_image1}                        
          />                             
        </Card> 
        </Hidden>

        <br/><br/>


        <Hidden smDown>
        <Card >                          
          <img
              src={ML_image2}                        
          />                              
        </Card> 
        </Hidden>

        <Hidden mdUp>
        <Card >                          
          <img
              src={ML_image2}                        
          />                             
        </Card> 
        </Hidden>

        <br/><br/>

        <a href="https://www.youtube.com/watch?v=rHeaoaiBM6Y">Machine Learning Video</a>  


        <br/><br/>
        <br/><br/>
        <Typography variant="h5" component="div">
          2.  The Story explaining the concept          
        </Typography>

        <Typography variant="h6" component="div">
          2.1	The Story
        </Typography>

        <Hidden smDown>
        <Card >                          
          <img
              src={ML_image3}                        
          />                              
        </Card> 
        </Hidden>

        <Hidden mdUp>
        <Card >                          
          <img
              src={ML_image3}                        
          />                             
        </Card> 
        </Hidden>


          <p>Once upon a time, there was a Credit Risk officer named Mr. Daljit Singh, who had worked in the bank for 30 years. </p>
          <p>Mr. Singh was known for his wisdom and keen eye for detail.  Over the years, he had developed an uncanny ability to predict whether a credit card holder would default on a payment or not.</p> 
          <p>But how did Mr. Singh acquire this skill?</p>

          <br />
          <Typography variant="h6" component="div">
            2.2	Break out group-based activity
          </Typography>

          <Typography variant="body2">

            <p>Discuss amongst yourself what factors effect Credit Card lending.</p>

            <br></br>

            <b>Learning from Experience</b>
            <p>Mr. Daljit Singh (He was very Diligent) learned from every credit risk rating he processed. </p>
            <p>He noticed patterns and trends in the data. For example, he observed that applicants with stable jobs and good credit histories were more likely to repay their credit cards. </p>
            <p>He also noticed that those with high debt-to-income ratios often struggled to make their payments.</p>

            <Hidden smDown>
              <Card >                          
                <img
                    src={ML_image4}                        
                />                              
              </Card> 
            </Hidden>

            <Hidden mdUp>
              <Card >                          
                <img
                    src={ML_image4}                        
                />                             
              </Card> 
            </Hidden>
            <br />
            <br />
            
            <b>Factors Considered by Mr. Singh</b>          
            <ol>
              <li><b>Age</b>
              <ul>
              <li><strong>Impact:</strong> Younger individuals may have less financial stability or shorter credit histories, potentially increasing default risk.</li>
              <li><strong>Consideration:</strong> Older applicants often have more established credit and financial habits.</li>
              </ul></li>
              <li><b>Marital Status</b>
              <ul>
              <li><strong>Impact:</strong> Married individuals might have shared financial responsibilities and potentially more stable household incomes.</li>
              <li><strong>Consideration:</strong> Single or divorced individuals may have different spending behaviors and financial obligations.</li>
              </ul></li>
              <li><b>Employment</b>
              <ul>
              <li><strong>Impact:</strong> Stable employment indicates consistent income, reducing the likelihood of default.</li>
              <li><strong>Consideration:</strong></li>
              <li><strong>Employment Duration:</strong> Longer tenure suggests job security.</li>
              <li><strong>Employment Type:</strong> Full-time positions may offer more stability than part-time or temporary jobs.</li>
              </ul></li>
              <li><b>Education Level</b>
              <ul>
              <li><strong>Impact:</strong> Higher education levels often correlate with better-paying jobs and financial literacy.</li>
              <li><strong>Consideration:</strong> Educated individuals may be more adept at managing credit and debt.</li>
              </ul></li>
              <li><b>Credit History</b>
              <ul>
              <li><strong>Impact:</strong> A strong credit history with timely payments lowers default risk.</li>
              <li><strong>Consideration:</strong></li>
              <li><strong>Payment History:</strong> Late payments or defaults in the past increase risk.</li>
              <li><strong>Length of Credit History:</strong> Longer histories provide more data on credit behavior.</li>
              </ul></li>
              <li><b>Salary (Income Level)</b>
              <ul>
              <li><strong>Impact:</strong> Higher income provides greater capacity to meet financial obligations.</li>
              <li><strong>Consideration:</strong> Income stability is just as important as income level.</li>
              </ul></li>
            </ol>

            <br />

            <b>Factors Restricted by Regulations</b>   
            <p>With several regulations like GDPR and other similar Regulatory oversights, personal information like Age, Marital Status, etc is strictly prohobited for being used in building AI Models. It is important to consult Regulatory guidelines of your organization before incorporating any Personal Data into AI Models</p>

            <details>
                <summary>The factors that would likely be restricted by GDPR regulations are</summary>
                <ul>
                  <li>Age: While age can be a factor in assessing creditworthiness, it is considered personal data that could be used for discrimination.</li>
                  <li>Marital Status: This is also considered personal data and could be used for discriminatory purposes.</li>
                </ul>
            </details>
              
            <br/><br/>

            

          <br/><br/>
          <Typography variant="h5" component="div">
            3.  Pipeline 
          </Typography>

          <p>As technology advanced, the bank decided to implement a machine learning model to assist in credit card default prediction. The model was trained on historical data, including all the factors Mr. Sharma had considered over the years. Here's how the supervised ML model worked:</p>
            <ol>
            <li><b>Data Collection:</b> The dataset used here is a credit card default dataset, which can be found here. This dataset includes various attributes related to loan applications.</li>
            <li><b>Data Preprocessing:</b> Cleaning and transforming the raw data into a suitable format for modeling. This includes handling missing values, scaling features, and encoding categorical variables.</li>
            <li><b>Feature Selection/Engineering:</b> Identifying the most important variables (features) that contribute to the model's prediction. You can also create new features based on existing ones to improve the model's performance.</li>
            <li><b>Model Selection:</b> Choosing the appropriate algorithm based on the problem (classification, regression, clustering).</li>
            <li><b>Model Training:</b> Fitting the model to the training data, allowing it to learn from the patterns.</li>
            <li><b>Hyperparameter Tuning:</b> Adjusting the model's hyperparameters to optimize its performance.</li>
            <li><b>Model Evaluation:</b> Testing the model's performance on unseen data using evaluation metrics like accuracy, precision, recall, F1-score, etc.</li>
            <li><b>Model Deployment:</b> Making the model available for real-world use.</li>
            <li><b>Continuous Learning:</b> The model was continuously updated with new data, improving its accuracy over time.</li>
            </ol>

          <br/><br/>


          <Typography variant="h5" component="div">
            4. Other Banking Industry Examples 
          </Typography>
          <ul>
            <li>Credit Underwriting: Using ML to assess credit risk more accurately1.</li>
            <li>Fraud Detection: Identifying suspicious transactions in real-time1.</li>
            <li>Customer Segmentation: Personalizing marketing efforts based on customer behavior1.</li>
          </ul>
          <br/><br/>

          <Typography variant="h5" component="div">
            5. Quiz, Recap and Q&A
          </Typography>


          <Typography variant="h6" component="div">
            5.1 Quiz
          </Typography>
          <ol>
            <li>
                <b>What is supervised learning?</b>
                <ul>
                    <li>A) Learning without labeled data</li>
                    <li>B) Learning with labeled data</li>
                    <li>C) Learning through reinforcement</li>
                    <li>D) Learning through unsupervised methods</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which of the following is a key factor in mortgage lending decisions?</b>
                <ul>
                    <li>A) Customer's favorite color</li>
                    <li>B) Customer's credit history</li>
                    <li>C) Customer's social media activity</li>
                    <li>D) Customer's preferred bank branch</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What is the purpose of feature engineering in an ML pipeline?</b>
                <ul>
                    <li>A) To collect data</li>
                    <li>B) To create new features from existing data</li>
                    <li>C) To deploy the model</li>
                    <li>D) To evaluate the model</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which algorithm is commonly used for classification tasks in supervised learning?</b>
                <ul>
                    <li>A) K-means clustering</li>
                    <li>B) Random Forest</li>
                    <li>C) Principal Component Analysis (PCA)</li>
                    <li>D) Apriori algorithm</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What does the term 'data preprocessing' refer to in an ML pipeline?</b>
                <ul>
                    <li>A) Collecting data from various sources</li>
                    <li>B) Cleaning and transforming data before training</li>
                    <li>C) Deploying the model to production</li>
                    <li>D) Monitoring model performance</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which of the following credit card utilization ratios would be considered a red flag for lenders?</b>
                <ul>
                    <li>A) 10%</li>
                    <li>B) 30%</li>
                    <li>C) 50%</li>
                    <li>D) 70%</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What is the role of a validation set in model training?</b>
                <ul>
                    <li>A) To train the model</li>
                    <li>B) To test the model's performance on unseen data</li>
                    <li>C) To preprocess the data</li>
                    <li>D) To deploy the model</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which tool can be used for containerizing a machine learning model?</b>
                <ul>
                    <li>A) Pandas</li>
                    <li>B) Docker</li>
                    <li>C) Scikit-learn</li>
                    <li>D) TensorFlow</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What is the primary goal of model evaluation?</b>
                <ul>
                    <li>A) To collect more data</li>
                    <li>B) To assess the model's performance using metrics</li>
                    <li>C) To deploy the model</li>
                    <li>D) To preprocess the data</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which of the following is a common metric used to evaluate the performance of a classification model?</b>
                <ul>
                    <li>A) Mean Squared Error</li>
                    <li>B) R-squared</li>
                    <li>C) F1 Score</li>
                    <li>D) Root Mean Squared Error</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What is the primary purpose of data preprocessing in an ML pipeline?</b>
                <ul>
                    <li>A) To deploy the model</li>
                    <li>B) To clean and prepare data for training</li>
                    <li>C) To collect data from various sources</li>
                    <li>D) To evaluate the model's performance</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which of the following is a common method for handling missing data in a dataset?</b>
                <ul>
                    <li>A) Ignoring the missing data</li>
                    <li>B) Filling missing values with the mean or median</li>
                    <li>C) Removing all rows with missing data</li>
                    <li>D) All of the above</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What is overfitting in the context of machine learning?</b>
                <ul>
                    <li>A) When a model performs well on training data but poorly on new, unseen data</li>
                    <li>B) When a model performs well on both training and test data</li>
                    <li>C) When a model performs poorly on both training and test data</li>
                    <li>D) When a model performs well on test data but poorly on training data</li>
                </ul>
                <br />
            </li>
            <li>
                <b>Which of the following is NOT a feature selection technique?</b>
                <ul>
                    <li>A) Principal Component Analysis (PCA)</li>
                    <li>B) Random Forest</li>
                    <li>C) Recursive Feature Elimination (RFE)</li>
                    <li>D) K-means clustering</li>
                </ul>
                <br />
            </li>
            <li>
                <b>What is the primary factor considered by lenders when evaluating a credit card applicant's creditworthiness?</b>
                <ul>
                    <li>A) Annual Income</li>
                    <li>B) Employment History</li>
                    <li>C) Credit Score</li>
                    <li>D) Debt-to-Income Ratio</li>
                </ul>
                <br />
            </li>
        </ol>

        <h6>Answers:</h6>
    <ol>
        <li>
            <details>
                <summary>Question 1</summary>
                B) Learning with labeled data
            </details>
        </li>
        <li>
            <details>
                <summary>Question 2</summary>
                B) Customer’s credit history
            </details>
        </li>
        <li>
            <details>
                <summary>Question 3</summary>
                B) To create new features from existing data
            </details>
        </li>
        <li>
            <details>
                <summary>Question 4</summary>
                B) Random Forest
            </details>
        </li>
        <li>
            <details>
                <summary>Question 5</summary>
                B) Cleaning and transforming data before training
            </details>
        </li>
        <li>
            <details>
                <summary>Question 6</summary>
                D) 70%
            </details>
        </li>
        <li>
            <details>
                <summary>Question 7</summary>
                B) To test the model’s performance on unseen data
            </details>
        </li>
        <li>
            <details>
                <summary>Question 8</summary>
                B) Docker
            </details>
        </li>
        <li>
            <details>
                <summary>Question 9</summary>
                B) To assess the model’s performance using metrics
            </details>
        </li>
        <li>
            <details>
                <summary>Question 10</summary>
                B) GitHub Actions
            </details>
        </li>
        <li>
            <details>
                <summary>Question 11</summary>
                B) To clean and prepare data for training
            </details>
        </li>
        <li>
            <details>
                <summary>Question 12</summary>
                D) All of the above
            </details>
        </li>
        <li>
            <details>
                <summary>Question 13</summary>
                A) When a model performs well on training data but poorly on new, unseen data
            </details>
        </li>
        <li>
            <details>
                <summary>Question 14</summary>
                D) K-means clustering
            </details>
        </li>
        <li>
            <details>
                <summary>Question 15</summary>
                C) Credit Score
            </details>
        </li>
    </ol>

    <br /><br />
    <Typography variant="h6" component="div">
            5.2 Recap and Q&A
    </Typography>

    </Typography>

    <Card >                          
          <img
              src={ML_image5}                        
          />                             
    </Card> 

    <Card >                          
          <img
              src={ML_image6}                        
          />                             
    </Card> 


    </CardContent>
    <CardActions>
      <Button onClick={onDownload} size="small">Click here to get access to the Code</Button>
    </CardActions>
  </React.Fragment>
);


function MachineLearning() {
  return (
  <Box sx={{ minWidth: 275 }}>
    <Card variant="outlined">{card}</Card>
  </Box>
  );
}

function onDownload() {
    const link = document.createElement("a");
    link.download = `Credit_Card_Default.zip`;
    link.href = Card_zip;
    link.click();
  };

export default MachineLearning;
