import { useState } from 'react';
import sidebarBg from './assets/bg1.jpg';
import { FaBars } from 'react-icons/fa';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';


//General Pages
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import AboutUs from './pages/AboutUs'
import Profile from './pages/Profile';
import ComingSoon from './pages/ComingSoon';

// Gen AI Pages
import GenAI from './pages/GenAIPages/GenAI';
import LLM from './pages/GenAIPages/LLM';
import SLM from './pages/GenAIPages/SLM';
// Gen AI Pages

// DeepLearning AI Pages
import DeepLearning from './pages/DeepLearning/DeepLearning';
import NLP from './pages/DeepLearning/NLP';
import NeuralNetworks from './pages/DeepLearning/NeuralNetwork/NeuralNetworks';
import RNN from './pages/DeepLearning/NeuralNetwork/RNN';
// DeepLearning AI Pages

// MachineLearning AI Pages
import MachineLearning from './pages/MachineLearning/MachineLearning';
import Regression from './pages/MachineLearning/Regression';
import Classification from './pages/MachineLearning/Classification';
import TimeSeries from './pages/MachineLearning/TimeSeries';
import BasianModels from './pages/MachineLearning/BasianModels';
// MachineLearning AI Pages



import './styles.scss';

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };


  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
    
      <BrowserRouter>
      
        <Sidebar
          image={sidebarBg}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />

        <main>
        
          
          <Header />

          <br />
          
          <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
                <FaBars />
          </div>
          
          
            <Switch>   
            
              <Route path="/genai" component={GenAI} />
              <Route path="/genai-llm" component={LLM} />
              <Route path="/genai-slm" component={SLM} />
              
              <Route path="/deeplearning" component={DeepLearning} />
              <Route path="/deeplearning-nlp" component={NLP} />
              <Route path="/deeplearning-neuralnetworks" component={NeuralNetworks} />
              <Route path="/deeplearning-neuralnetworks-rnn" component={RNN} />

              <Route path="/ml" component={MachineLearning} />
              <Route path="/ml-regression" component={Regression} />
              <Route path="/ml-classification" component={Classification} />
              <Route path="/ml-timeseries" component={TimeSeries} />
              <Route path="/ml-basian" component={BasianModels} />

              <Route path="/profile" component={Profile} />
              <Route path="/not-found" component={NotFound} />
              <Route path="/about-us" component ={AboutUs} />                 
              
              <Route path="/coming-soon" component ={ComingSoon} /> 

              <Route path="/" component ={Home} />   
              
              <Redirect to="/not-found" />                     
            </Switch>
         
            <Footer />          
        </main>
      </BrowserRouter>

    </div>
  );
}

export default App;