import React from "react";
import Stack from '@mui/material/Stack';
import Footer_Image from '../assets/Footer_Image.png'

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div>
      <br/>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
        <p></p>
        <p>Copyright ⓒ {year}</p>
        <img src={Footer_Image} height={10} width={55}/>
      </Stack>
      
    </div>
  );
}

export default Footer;
