import * as React from 'react';

import {  Box, Card, CardContent, Typography, Divider} from '@mui/material';

import ArticleImageCardSumit from '../components/ArticleImageCardSumit';
import FourCardsLinksSumit from '../components/FourCardsLinksSumit';

import HomePage_Image from '../assets/HomePage_Image.jpg'
import GenAI_Image from '../assets/GenAI_Image.jpg'
import DeepLearning_Image from '../assets/DeepLearning_Image.jpeg'
import MachineLearning_Image from '../assets/MachineLearning_Image.jpg'
import CoreConcepts_Image from '../assets/CoreConcepts_Image.jpg'




const Home = () => {
  
  return (
    <div>
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">   
          <CardContent>
            <ArticleImageCardSumit
                      article_image = {HomePage_Image} 
                      artile_heading = "Artificial Intelligence: GenAI | DeepLearning | MachineLearning | Core Concepts"
                      article_tagline = "Your quest for knowledge starts here ..."
            />

            <br/>
            <Divider />
            <br/>

            <Typography variant="h6" component="div">
              AI Made Simple
            </Typography>
            <Typography variant="body2">    
              No prior AI knowledge? No problem. Each topic begins with a ‘Simplified Concepts’ box, breaking down complex AI theories into easy-to-grasp fundamentals. It’s AI demystified, perfect for those just starting out.
            </Typography>

            <br />
            <Typography variant="h6" component="div">
              Advanced Insights
            </Typography>
            <Typography variant="body2">    
              For the seasoned professional, we delve into the intricate details of AI applications and theories. Expand your expertise with our in-depth analyses designed for advanced learners.
            </Typography>

            <br />
            <Typography variant="h6" component="div">
              Data Science Deep Dive
            </Typography>
            <Typography variant="body2">    
            Data scientists, get ready to code. Beneath each topic’s advanced section, you’ll find ‘Data Scientist’s Corner’—a treasure trove of Python code examples and detailed explorations to enhance your practical AI skills.
            </Typography>

          </CardContent>
        </Card>
      

        <br />
        <br />

        <FourCardsLinksSumit 
          link1="/genai" 
          image1 = {GenAI_Image}
          heading1="Generative AI" 
          description1="GenAI can create new things like text, images, and even videos! What will you create with AI?  Click to learn more."
          

          link2="/deeplearning" 
          image2 = {DeepLearning_Image}
          heading2="Deep Learning" 
          description2="Imagine computers that learn like brains. Deep learning unlocks that power. What will they learn next?"
          

          link3="/ml" 
          image3 = {MachineLearning_Image}
          heading3="Machine Learning" 
          description3="Teach machines to think for themselves. Explore Machine Learning!"


          link4="/coming-soon" 
          image4 = {CoreConcepts_Image}
          heading4="Core Concepts" 
          description4="AI's ABCs: Math, stats & algorithms that make machines smart. Click!"        
        />  
        
      </Box>

      <br />
    </div>
  );
};

export default Home;
