function Regression() {
    return (
      <div>
      <h1>Regression</h1>
        <p>
            Linear Regression
        </p>      
      </div>
    );
  }
  
  export default Regression;
  