import * as React from 'react';
import { Box, Card, CardContent} from '@mui/material';

import ArticleImageCardSumit from '../components/ArticleImageCardSumit';

import ComingSoon_Image from '../assets/ComingSoon_Image.jpg'

const ComingSoon = () => {  
       
    return(
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined" >
                <CardContent>                          
                <ArticleImageCardSumit
                      article_image = {ComingSoon_Image} 
                      artile_heading = "Coming Soon"
                      article_tagline = "Stay Tuned ..."
                />           
                </CardContent>
            </Card>  
        
            <br/>     
       
        </Box>


    )

}

export default ComingSoon