function NLP() {
    return (
      <div>
      <h1>Natural Language Processing</h1>
        <p>
            NLP
        </p>      
      </div>
    );
  }
  
  export default NLP;
  